<template>
  <tr :class="[selected ? 'bg-indigo-50' : '']">
    <slot />
  </tr>
</template>

<script setup lang="ts">
import { provide, ref, watch } from 'vue';
import { SelectedRowContextId } from './context';

const props = defineProps<{
  selected?: boolean;
}>();

const isSelected = ref(props.selected);

provide(SelectedRowContextId, isSelected);

watch(
  () => props.selected,
  (value) => {
    isSelected.value = value;
  },
);
</script>

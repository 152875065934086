<template>
  <th
    scope="col"
    :class="[
      align === 'left' ? 'text-left' : '',
      align === 'center' ? 'text-center' : '',
      align === 'right' ? 'text-right' : '',
      compact ? 'w-[0.1%]' : '',
      reader
        ? 'relative py-3.5 pl-3 pr-4 sm:pr-6 whitespace-nowrap'
        : 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
      'first:rounded-tl-lg last:rounded-tr-lg',
    ]"
  >
    <div class="flex items-center" v-if="sortable">
      <slot />
      <TableHeadColumnSortingIcon
        :sorted="sorted"
        @sorting-button-clicked="emits('sortingButtonClicked')"
        class="ml-2"
      />
    </div>
    <slot v-else />
  </th>
</template>

<script setup lang="ts">
import TableHeadColumnSortingIcon from './TableHeadColumnSortingIcon.vue';

defineProps<{
  reader?: boolean;
  compact?: boolean;
  sorted?: 'asc' | 'desc' | null;
  sortable?: boolean;
  align?: 'left' | 'center' | 'right';
}>();

const emits = defineEmits<{
  (event: 'sortingButtonClicked'): void;
}>();
</script>

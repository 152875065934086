import { ApiClientInterface, schema } from '@ambassy/api-client';
import { ReviewStatus } from '../../../Kernel/Core/Review/reviewStatus';
import { Ambassador } from '../../Ambassador/types';
import { Quest } from '../../../Kernel/Core/Quest/quest';
import { Reward } from '../../../Kernel/Core/Reward/reward';
import { ReviewSubmissionsCountResponse } from './types';
import {
  AiReviewFeedbackCategoriesResponse,
  AiReviewFeedbackRequest,
  AmbassadorsOutput,
  PaginatedReviewSubmissionsRequest,
  PaginatedReviewSubmissionsResponse,
  QuestsOutput,
  RewardsResponse,
} from '@ambassy/schema';

export type ReviewSubmissionResponse =
  schema.components['schemas']['ReviewSubmissionOutput'];

interface ReviewSubmissionsResourceInterface {
  getReviewSubmissions(
    status: ReviewStatus,
    page: number,
    questIds?: string[],
    questTypes?: string[],
    search?: string,
  ): Promise<PaginatedReviewSubmissionsResponse>;

  getAmbassadors(ids: string[]): Promise<AmbassadorsOutput>;

  getQuests(ids: string[]): Promise<QuestsOutput>;

  approve(reviewSubmissionId: string): Promise<void>;

  reject(reviewSubmissionId: string, reason: string): Promise<void>;

  revokeApproval(reviewSubmissionId: string): Promise<void>;

  getRewardPoints(questIds: string[]): Promise<Reward[]>;

  getRewardPoints(questIds: string[]): Promise<RewardsResponse>;

  getSubmissionsCount(
    status: PaginatedReviewSubmissionsRequest['status'],
  ): Promise<ReviewSubmissionsCountResponse>;

  getSubmission(reviewSubmissionId: string): Promise<ReviewSubmissionResponse>;

  submitFeedback(data: AiReviewFeedbackRequest): Promise<void>;

  getFeedbackCategories(): Promise<AiReviewFeedbackCategoriesResponse>;
}

export type ReviewSubmissionsListInterface =
  PaginatedReviewSubmissionsResponse['items'][number];

export interface ReviewWithAmbassadorInterface
  extends ReviewSubmissionsListInterface {
  ambassador: Ambassador;
}

export interface ReviewSubmissionsWithQuestInterface
  extends ReviewWithAmbassadorInterface {
  quest: Quest;
}

export interface FullReviewSubmissionInterface
  extends ReviewSubmissionsWithQuestInterface {
  reward: Reward;
}

export default class ReviewSubmissionsResource
  implements ReviewSubmissionsResourceInterface
{
  constructor(private readonly apiClient: ApiClientInterface) {}

  public getReviewSubmissions(
    status: ReviewStatus,
    page: number,
    questIds?: string[],
    questTypes?: string[],
    search?: string,
  ): Promise<PaginatedReviewSubmissionsResponse> {
    return this.apiClient.getWithError<PaginatedReviewSubmissionsResponse>(
      `/api/v1/quests/reviews-submissions`,
      {
        status,
        page,
        questIds,
        questTypes,
        search,
      },
    );
  }

  public getAmbassadors(ids: string[]): Promise<AmbassadorsOutput> {
    if (ids.length === 0) return Promise.resolve([]);
    return this.apiClient.getWithError<AmbassadorsOutput>(
      `/api/v1/review/ambassadors`,
      {
        ids,
      },
    );
  }

  public getQuests(ids: string[]): Promise<QuestsOutput> {
    if (ids.length === 0) return Promise.resolve([]);
    return this.apiClient.getWithError<QuestsOutput>(
      '/api/v1/review/quests?withDeleted=true',
      {
        questIds: ids,
      },
    );
  }

  approve(reviewSubmissionId: string): Promise<void> {
    return this.apiClient.postWithError(
      `/api/v1/review/quests/${reviewSubmissionId}/approve`,
    );
  }

  reject(reviewSubmissionId: string, reason: string): Promise<void> {
    return this.apiClient.postWithError(
      `/api/v1/review/quests/${reviewSubmissionId}/reject`,
      {
        reason,
      },
    );
  }

  revokeApproval(reviewSubmissionId: string): Promise<void> {
    return this.apiClient.postWithError(
      `/api/v1/review/quests/${reviewSubmissionId}/revoke-approval`,
    );
  }

  getRewardPoints(questIds: string[]): Promise<RewardsResponse> {
    if (questIds.length === 0) return Promise.resolve([]);
    return this.apiClient.getWithError<RewardsResponse>(
      '/api/v1/review/quests/rewards',
      { questIds },
    );
  }

  getSubmissionsCount(
    status: PaginatedReviewSubmissionsRequest['status'],
  ): Promise<ReviewSubmissionsCountResponse> {
    return this.apiClient.getWithError(
      '/api/v1/quests/reviews-submissions/count',
      { status },
    );
  }

  getSubmission(reviewSubmissionId: string): Promise<ReviewSubmissionResponse> {
    return this.apiClient.get(
      `/api/v1/quests/reviews-submissions/${reviewSubmissionId}`,
    );
  }

  submitFeedback(data: AiReviewFeedbackRequest): Promise<void> {
    return this.apiClient.postWithError('/api/v1/admin/ai-feedback', data);
  }

  getFeedbackCategories(): Promise<AiReviewFeedbackCategoriesResponse> {
    return this.apiClient.getWithError('/api/v1/admin/ai-feedback/categories');
  }
}

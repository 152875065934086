<template>
  <nav class="flex grow flex-col px-2 pb-4 space-y-1">
    <NavLink :active="isCurrentRoute('dashboard')" href="dashboard">
      <div class="mr-3 flex-shrink-0 text-gray-800">
        <img src="/assets/icons/Home.svg" alt="Fulfillment" class="h-6 w-6" />
      </div>
      Home
    </NavLink>
    <NavLink
      :active="isCurrentRoute('ambassadors.index')"
      :href="'ambassadors.index'"
      data-key="t-rewards"
    >
      <div class="mr-3 flex-shrink-0 text-gray-800">
        <img src="/assets/icons/Users.svg" alt="Fulfillment" class="h-6 w-6" />
      </div>
      Ambassadors
      <StatusBadge
        v-if="
          !ambassadorCountStore.isLoading &&
          ambassadorCountStore.ambassadorCount > 0
        "
        :type="BadgeType.INFO"
        class="ml-2"
      >
        <span class="w-2 h-2 rounded-full bg-indigo-500 inline-block mr-1" />
        <span>{{ ambassadorCountStore.ambassadorCount }}</span>
      </StatusBadge>
    </NavLink>
    <NavLink
      :active="isCurrentRoute('quests.index')"
      :href="'quests.index'"
      data-key="t-quests"
    >
      <div class="mr-3 flex-shrink-0 text-gray-800">
        <img src="/assets/icons/Flag.svg" alt="Fulfillment" class="h-6 w-6" />
      </div>
      Quests
    </NavLink>
    <NavLink
      :active="isCurrentRoute('rewards.index')"
      :href="'rewards.index'"
      data-key="t-review"
    >
      <div class="mr-3 flex-shrink-0 text-gray-800">
        <img src="/assets/icons/Gift.svg" alt="Fulfillment" class="h-6 w-6" />
      </div>
      Rewards
    </NavLink>
    <NavLink
      :active="isCurrentRoute('quests.review.index')"
      :href="'quests.review.index'"
      data-key="t-review"
    >
      <div class="mr-3 flex-shrink-0 text-gray-800">
        <img
          src="/assets/icons/Document%20search.svg"
          alt="Fulfillment"
          class="h-6 w-6"
        />
      </div>
      Review
      <StatusBadge
        v-if="!reviewCountStore.isLoading && reviewCountStore.reviewCount > 0"
        :type="BadgeType.INFO"
        class="ml-2"
      >
        <span
          class="w-2 h-2 rounded-full bg-indigo-500 inline-block mr-1"
        ></span>
        <span>{{ reviewCountStore.reviewCount }}</span>
      </StatusBadge>
    </NavLink>
    <NavLink
      :active="isCurrentRoute('fulfillment.index')"
      :href="'fulfillment.index'"
    >
      <div class="mr-3 flex-shrink-0 text-gray-800">
        <img
          src="/assets/icons/fulfillment.svg"
          alt="Fulfillment"
          class="h-6 w-6"
        />
      </div>
      Fulfillment
      <StatusBadge
        v-if="
          !rewardClaimCountStore.isLoading &&
          rewardClaimCountStore.fulfillmentCount > 0
        "
        :type="BadgeType.INFO"
        class="ml-2"
      >
        <span
          class="w-2 h-2 rounded-full bg-indigo-500 inline-block mr-1"
        ></span>
        <span>{{ rewardClaimCountStore.fulfillmentCount }}</span>
      </StatusBadge>
    </NavLink>
    <NavLink
      :active="isCurrentRoute('analytics.index')"
      :href="'analytics.index'"
    >
      <div class="mr-3 flex-shrink-0 text-gray-800">
        <img
          src="/assets/icons/Chart%20bar.svg"
          alt="Analytics"
          class="h-6 w-6"
        />
      </div>
      Analytics
    </NavLink>
    <NavLink
      :active="isCurrentRoute('ambassadors.landing')"
      :href="'ambassadors.landing'"
    >
      <div class="mr-3 flex-shrink-0 text-gray-800">
        <img
          src="/assets/icons/Clipboard%20list.svg"
          alt="Fulfillment"
          class="h-6 w-6"
        />
      </div>
      Landing pages
    </NavLink>
    <NavLink
      :active="
        isOneOfRoute([
          'settings.index',
          'settings.appearance',
          'settings.tags',
          'integrations.index',
          'landing.fields',
          'settings.users',
          'settings.app-language',
          'settings.company',
          'settings.whatsapp-templates',
          'settings.export',
          'settings.finance',
        ])
      "
      :href="'settings.index'"
      data-key="t-rewards"
    >
      <div class="mr-3 flex-shrink-0 text-gray-800">
        <img src="/assets/icons/Cog.svg" alt="Fulfillment" class="h-6 w-6" />
      </div>
      Settings
    </NavLink>
    <div
      v-if="
        isOneOfRoute([
          'settings.index',
          'settings.appearance',
          'settings.tags',
          'integrations.index',
          'landing.fields',
          'settings.users',
          'settings.app-language',
          'settings.company',
          'settings.whatsapp-templates',
          'settings.export',
          'settings.finance',
        ])
      "
    >
      <NavSubLink
        :active="isCurrentRoute('settings.appearance')"
        :href="'settings.appearance'"
      >
        Appearance
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('integrations.index')"
        :href="'integrations.index'"
        data-key="t-rewards"
      >
        Integrations
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('settings.tags')"
        href="settings.tags"
        data-key="t-tags"
      >
        Tags
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('landing.fields')"
        :href="'landing.fields'"
      >
        Fields
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('settings.users')"
        :href="'settings.users'"
      >
        Users
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('settings.app-language')"
        :href="'settings.app-language'"
      >
        App Language
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('settings.company')"
        :href="'settings.company'"
      >
        Company Details
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('settings.whatsapp-templates')"
        :href="'settings.whatsapp-templates'"
      >
        WhatsApp Templates
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('settings.export')"
        :href="'settings.export'"
      >
        Export
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('settings.finance')"
        :href="'settings.finance'"
      >
        Finance
      </NavSubLink>
    </div>
    <div v-if="isAdmin">
      <NavLink
        :active="
          isCurrentRoute('admin.settings.mail') ||
          isCurrentRoute('admin.settings.whatsapp') ||
          isCurrentRoute('admin.settings.pre-register')
        "
        :href="'admin.settings.mail'"
        data-key="t-rewards"
      >
        <div class="mr-3 flex-shrink-0 text-gray-800">
          <img src="/assets/icons/Cog.svg" alt="Fulfillment" class="h-6 w-6" />
        </div>
        Employees
      </NavLink>
      <NavSubLink
        :active="isCurrentRoute('admin.settings.mail')"
        :href="'admin.settings.mail'"
      >
        Email settings
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('admin.settings.reconciliation')"
        :href="'admin.settings.reconciliation'"
      >
        Reconciliation
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('admin.settings.tools')"
        :href="'admin.settings.tools'"
      >
        Tools
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('admin.settings.whatsapp')"
        :href="'admin.settings.whatsapp'"
      >
        Whatsapp settings
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('admin.finance.index')"
        :href="'admin.finance.index'"
      >
        Finance
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('admin.settings.pre-register')"
        :href="'admin.settings.pre-register'"
      >
        Pre-registers
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('contacts.index')"
        :href="'contacts.index'"
      >
        Contacts
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('whatsapp-templates.index')"
        :href="'whatsapp-templates.index'"
      >
        Whatsapp Templates
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('whatsapp-shop.index')"
        :href="'whatsapp-shop.index'"
      >
        Whatsapp Shops
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('workflows.index')"
        :href="'workflows.index'"
      >
        Workflows
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('admin.metrics')"
        :href="'admin.metrics'"
      >
        Community manager
      </NavSubLink>
      <NavSubLink
        :active="isCurrentRoute('whatsapp.conversations')"
        :href="'whatsapp.conversations'"
      >
        Conversations
      </NavSubLink>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import NavLink from '../Components/Nav/NavLink.vue';
import NavSubLink from '../Components/Nav/NavSubLink.vue';
import { apiClient, useUser } from '@ambassy/api-client';
import { useRouter } from 'vue-router';
import { AmbassadorDashboardStatus } from '../Pages/Ambassador/types';
import { BadgeType } from '../Kernel/Core/Badge/badgeType';
import { StatusBadge } from '@ambassy/innercrowd-ui';
import { ReviewStatus } from '../Kernel/Core/Review/reviewStatus';
import { useAmbassadorCountStore } from '../Pages/Ambassador/Stores/ambassadorCountStore';
import { useReviewCountStore } from '../Pages/Review/Store/reviewCountStore';
import { RewardClaimsResource } from '../Pages/Fulfillment/Resources/rewardClaimsResource';
import { useFulfillmentCountStore } from '../Pages/Fulfillment/Stores/fufillmentCountStore';
import { UserResponse } from '@ambassy/schema';
import { computed, ref } from 'vue';

const user = ref<UserResponse>();
useUser().then((res) => (user.value = res));
const router = useRouter();

const ambassadorCountStore = useAmbassadorCountStore();
const reviewCountStore = useReviewCountStore();
const rewardClaimCountStore = useFulfillmentCountStore();

const isCurrentRoute = (route: string): boolean => {
  return window.location.pathname === router.resolve({ name: route }).fullPath;
};

const isOneOfRoute = (routes: string[]): boolean => {
  return routes.map((route) => isCurrentRoute(route)).includes(true);
};

const isAdmin = computed(() => {
  return user.value?.isAdmin;
});
</script>

<template>
  <Modal :show="open" @close="closeModel">
    <h3 class="text-center font-bold">Switch Accounts</h3>

    <template v-if="isLoading">
      <div class="flex justify-center">
        <Spinner class="w-6 h-6" />
      </div>
    </template>
    <template v-else>
      <SearchListInput
        :options="orgOptions"
        :model-value="selected"
        @update:model-value="selectOrganisation"
      />
      <InputError :message="error" />
    </template>
    <div class="flex justify-between mt-64 space-x-4">
      <SecondaryButton class="w-full" @secondary-button-clicked="closeModel"
        >Cancel</SecondaryButton
      >
      <PrimaryButton class="w-full" @primary-button-clicked="switchAccount"
        >Switch</PrimaryButton
      >
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '../../molecules/Modal/Modal.vue';
import SecondaryButton from '../../atoms/Buttons/SecondaryButton.vue';
import PrimaryButton from '../../atoms/Buttons/PrimaryButton.vue';
import { computed, ref } from 'vue';
import SearchListInput from '../../atoms/Input/SearchListInput.vue';
import InputError from '../../atoms/Input/InputError.vue';
import Spinner from '../../atoms/Loaders/Spinner.vue';
import { UserOutput } from '@ambassy/schema';
import { OptionsType } from '../../atoms/Input/types';

const props = defineProps<{
  open: boolean;
  options: UserOutput['user']['organisations'];
  isLoading: boolean;
}>();

const selected = ref<OptionsType[number] | null>(null);
const error = ref<string>();
const orgOptions = computed(
  () =>
    props.options?.map((option) => ({
      key: option.id,
      value: option.id,
      label: option.name,
    })) ?? [],
);

const emits = defineEmits<{
  (event: 'close'): void;
  (event: 'switch', value: string): void;
}>();

const closeModel = () => {
  emits('close');
};

const switchAccount = () => {
  if (!selected.value) {
    error.value = 'Please select an account';
    return;
  }
  emits('switch', selected.value.key);
};

const selectOrganisation = (value: any) => {
  selected.value = value;
  error.value = '';
};
</script>

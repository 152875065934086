import { NavigationGuardWithThis } from 'vue-router';
import { authenticationService } from './index';
import { apiClient } from '../client/apiClient';
import { authorisePushNotifications } from '../push/echo';

export const loggedInUserGuard: NavigationGuardWithThis<unknown> = async (
  to,
  _from,
  next,
) => {
  if (await authenticationService.isLegacyToken()) {
    await authenticationService.exchangeLegacyToken();
  }

  if (await authenticationService.isLoggedIn()) {
    const token = await authenticationService.getToken();
    apiClient.authorize(
      token,
      await authenticationService.getSelectedOrganisationId(),
    );
    // await authorisePushNotifications(token);
    next();

    return;
  }

  authenticationService.redirectToLogin(to.fullPath);
};

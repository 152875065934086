<script setup>
import { computed } from 'vue';

const props = defineProps(['href', 'active']);

const classes = computed(() =>
  props.active
    ? 'bg-indigo-200 text-indigo-600 border-l-4 border-indigo-400'
    : 'bg-white text-gray-600 hover:bg-indigo-200',
);
</script>

<template>
  <RouterLink
    :to="{ name: href }"
    :class="[
      classes,
      'group flex items-center px-2 py-2 text-sm leading-5 font-medium',
    ]"
  >
    <slot />
  </RouterLink>
</template>

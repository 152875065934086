import { useAccountStore } from './accountStore';
import { useAmbassadorCountStore } from '../../Pages/Ambassador/Stores/ambassadorCountStore';
import { useReviewCountStore } from '../../Pages/Review/Store/reviewCountStore';
import { useFulfillmentCountStore } from '../../Pages/Fulfillment/Stores/fufillmentCountStore';
import AmbassadorsResource from '../../Pages/Ambassador/Resources/ambassadorsResource';
import { apiClient } from '@ambassy/api-client';
import ReviewSubmissionsResource from '../../Pages/Review/Resources/reviewSubmissionsResource';
import { FulfillmentCountResponse, UserResponse } from '@ambassy/schema';
import { initIntercom } from '../../../intercom';

let loaded = false;
const initialLoad = async () => {
  if (loaded) {
    return;
  }

  const ambassadorCountStore = useAmbassadorCountStore();
  const reviewCountStore = useReviewCountStore();
  const rewardClaimCountStore = useFulfillmentCountStore();

  const ambassadorResource = new AmbassadorsResource(apiClient);
  const reviewResource = new ReviewSubmissionsResource(apiClient);

  rewardClaimCountStore.startLoading();
  rewardClaimCountStore.clearError();
  apiClient
    .getWithError<FulfillmentCountResponse>('/api/v1/reward-claims/count', {
      status: 'pending',
    })
    .then(rewardClaimCountStore.setFulfillmentCount)
    .catch(rewardClaimCountStore.setError)
    .finally(rewardClaimCountStore.stopLoading);

  ambassadorCountStore.startLoading();
  ambassadorCountStore.clearError();
  ambassadorResource
    .getAmbassadorCount('new')
    .then(ambassadorCountStore.setAmbassadorCount)
    .catch(ambassadorCountStore.setError)
    .finally(ambassadorCountStore.stopLoading);

  reviewCountStore.startLoading();
  reviewCountStore.clearError();
  reviewResource
    .getSubmissionsCount('PENDING')
    .then(reviewCountStore.setReviewCount)
    .catch(reviewCountStore.setError)
    .finally(reviewCountStore.stopLoading);

  loaded = true;
};

export default initialLoad;

<template>
  <div class="cursor-pointer" @click="emits('sortingButtonClicked')">
    <ChevronUpIcon
      :class="[
        sorted === 'desc' ? 'text-gray-900' : 'text-gray-400',
        'w-3 h-3 leading-3 p-0 m-0 bold',
      ]"
      aria-hidden="true"
    />
    <ChevronDownIcon
      :class="[
        sorted === 'asc' ? 'text-gray-900' : 'text-gray-400',
        'w-3 h-3 leading-3 p-0 m-0 bold',
      ]"
      aria-hidden="true"
    />
  </div>
</template>

<script setup lang="ts">
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/solid';

defineProps<{
  sorted?: 'asc' | 'desc' | null;
}>();

const emits = defineEmits<{
  (event: 'sortingButtonClicked'): void;
}>();
</script>

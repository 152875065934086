<template>
  <div class="flex flex-1 justify-between px-4 z-10">
    <div></div>
    <div class="ml-4 flex items-center space-x-8 md:ml-6">
      <div class="flex items-center">
        <div class="flex items-center space-x-2">
          <QuestionMarkCircleIcon class="h-4 w-4" />
          <TextButton
            class="text-black hover:text-gray-700"
            @text-button-clicked="openHelp"
            >Help</TextButton
          >
        </div>
      </div>
      <div class="flex items-center">
        <div class="flex items-center space-x-2">
          <EyeIcon class="h-4 w-4" />
          <TextButton
            class="text-black hover:text-gray-700"
            @text-button-clicked="goToApp"
            :is-loading="gotoAppIsProcessing"
            >Ambassador App
          </TextButton>
        </div>
      </div>
      <Menu as="div" class="relative ml-3">
        <div>
          <MenuButton
            class="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <div class="flex items-center space-x-2">
              <UserIcon class="h-4 w-4" />
              <TextButton class="text-black hover:text-gray-700">
                {{
                  accountStore.selectedUserOrganisation?.name ??
                  accountStore.user?.name ??
                  ''
                }}
              </TextButton>
              <ChevronDownIcon class="h-4 w-4" />
            </div>
          </MenuButton>
        </div>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <MenuItem>
              <div
                role="button"
                @click="signout"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
              >
                Sign out
              </div>
            </MenuItem>
            <MenuItem v-if="(user?.user.organisations?.length ?? 0) > 1">
              <div
                role="button"
                @click="openSwitchModel"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
              >
                Switch account
              </div>
            </MenuItem>
          </MenuItems>
        </transition>
      </Menu>
    </div>
  </div>
  <SwitchAccountModal
    v-if="user"
    :open="switchModelOpen"
    :options="organisations"
    :is-loading="isLoading"
    @close="switchModelOpen = false"
    @switch="switchAccount"
  />
</template>

<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import {
  apiClient,
  storageService,
  schema,
  useUser,
  tabStorageService,
  USER_SELECTED_ORGANISATION_ID,
} from '@ambassy/api-client';
import { API_TOKEN_STORAGE_KEY } from '@ambassy/api-client';
import {
  UserIcon,
  ChevronDownIcon,
  EyeIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/vue/24/outline';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import {
  SwitchAccountModal,
  TextButton,
  useToast,
} from '@ambassy/innercrowd-ui';
import { useAccountStore } from '../Support/User/accountStore';
import { OrganisationsResponse, UserResponse } from '@ambassy/schema';

const accountStore = useAccountStore();
const router = useRouter();
useUser().then((res) => (user.value = res));
const user = ref<UserResponse>();

const switchModelOpen = ref(false);

const organisations = ref<OrganisationsResponse>([]);
const isLoading = ref(false);

const signout = () => {
  storageService.removeItem(API_TOKEN_STORAGE_KEY);
  router.push({ name: 'login' });
};

const openSwitchModel = () => {
  fetchOrganisations();
  switchModelOpen.value = true;
};

const switchAccount = (organisationId: string) => {
  apiClient
    .postWithError('/api/v1/organisations/switch', {
      organisationId,
    })
    .then(() => {
      switchModelOpen.value = false;
      router.push({ name: 'dashboard' });
      window.location.reload();
      tabStorageService.setItem(USER_SELECTED_ORGANISATION_ID, organisationId);
    });
};

const fetchOrganisations = () => {
  isLoading.value = true;
  apiClient
    .getWithError<OrganisationsResponse>('/api/v1/organisations')
    .then((response) => {
      organisations.value = response;
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const toast = useToast();
const gotoAppIsProcessing = ref(false);
type AppLoginResponse = schema.components['schemas']['AppLoginOutput'];
const goToApp = () => {
  gotoAppIsProcessing.value = true;
  apiClient
    .getWithError<AppLoginResponse>('/api/v1/organisation/app-login', {
      deviceName: navigator.userAgent,
    })
    .then((response) => {
      window.open(response.url, '_blank');
    })
    .catch((error) => {
      toast.error(
        error.message,
        'Please first register yourself via a landing page as an ambassador with the same email address you are now logged in.',
        [
          {
            label: 'Register via landing page',
            onClick: () => {
              router.push({ name: 'ambassadors.landing' });
            },
          },
        ],
      );
    })
    .finally(() => {
      gotoAppIsProcessing.value = false;
    });
};

const openHelp = () => {
  window.open('https://intercom.help/innercrowd/en/', '_blank');
};
</script>

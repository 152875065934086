import { defineStore } from 'pinia';
import {
  ClientErrorInterface,
  FulfillmentCountResponse,
  PaginatedRewardClaimsResponse,
  PaginatedRewardProductsResponse,
} from '@ambassy/schema';

export const useFulfillmentCountStore = defineStore('fulfillmentCount', {
  state: () => ({
    isLoading: true,
    fulfillmentCount: 0,
    error: null as string | null,
  }),
  actions: {
    startLoading() {
      this.isLoading = true;
    },
    clearError() {
      this.error = null;
    },
    stopLoading() {
      this.isLoading = false;
    },
    setError(error: ClientErrorInterface<any>) {
      this.error = error.message;
    },
    setFulfillmentCount(count: FulfillmentCountResponse) {
      this.fulfillmentCount = count.count;
    },
    decreaseFulfillmentCount(amount: number = 1) {
      this.fulfillmentCount -= amount;
    },
    increaseFulfillmentCount(amount: number = 1) {
      this.fulfillmentCount += amount;
    },
  },
});

import AmbassadorsResource from '../Resources/ambassadorsResource';
import { apiClient, ClientError } from '@ambassy/api-client';
import { defineStore } from 'pinia';
import { AmbassadorDashboardStatus } from '../types';
import { AmbassadorCountResponse } from '../Resources/types';

export const useAmbassadorCountStore = defineStore('ambassadorCount', {
  state: () => ({
    isLoading: true,
    ambassadorCount: 0 as number | undefined,
    error: null as string | null,
  }),
  actions: {
    startLoading() {
      this.isLoading = true;
    },
    clearError() {
      this.error = null;
    },
    stopLoading() {
      this.isLoading = false;
    },
    setError(error: ClientError<any>) {
      this.error = error.message;
    },
    setAmbassadorCount(count: AmbassadorCountResponse) {
      this.ambassadorCount = count.count;
    },
    async decrementAmbassadorCount() {
      if (this.ambassadorCount === undefined) {
        return;
      }
      this.ambassadorCount -= 1;
    },
  },
});

import { defineStore } from 'pinia';
import { OrganisationMetricsResponse } from '@ambassy/schema';

interface OrganisationMetricsState {
  metrics: OrganisationMetricsResponse | null;
  isLoading: boolean;
  error: string | null;
}

export const useOrganisationMetricsStore = defineStore('organisationMetrics', {
  state: (): OrganisationMetricsState => ({
    metrics: null,
    isLoading: true,
    error: null,
  }),

  actions: {
    startLoading() {
      this.isLoading = true;
    },

    stopLoading() {
      this.isLoading = false;
    },

    setMetrics(metrics: OrganisationMetricsResponse) {
      this.metrics = metrics;
    },

    setError(err: Error) {
      this.error = err.message;
    },

    clearError() {
      this.error = null;
    },
  },
});

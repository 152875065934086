<template>
  <AuthenticatedLayout>
    <DashboardTitle class="mb-8">Organisation Metrics</DashboardTitle>

    <ErrorDashboardState v-if="error" class="mb-16">
      <h4 class="text-center">{{ error }}</h4>
    </ErrorDashboardState>

    <EmptyDashboardState
      class="mb-12"
      v-else-if="!isLoading && !metrics?.length"
    >
      <BuildingOfficeIcon class="w-6 h-6 mx-auto text-indigo-600" />
      <StateTitle>No organisations yet</StateTitle>
      <StateSubTitle>There are no organisations in the system</StateSubTitle>
    </EmptyDashboardState>

    <Table v-else>
      <TableHead>
        <TableHeadColumn> Organisation </TableHeadColumn>
        <TableHeadColumn
          align="left"
          sortable
          :sorted="sortBy === 'open_reviews' ? sortDirection : undefined"
          @sorting-button-clicked="setSorting('open_reviews')"
          compact
        >
          Open Reviews
        </TableHeadColumn>
        <TableHeadColumn
          align="left"
          sortable
          :sorted="sortBy === 'unfulfilled_rewards' ? sortDirection : undefined"
          @sorting-button-clicked="setSorting('unfulfilled_rewards')"
          compact
        >
          Unfulfilled Rewards
        </TableHeadColumn>
        <TableHeadColumn
          align="left"
          sortable
          :sorted="sortBy === 'pending_ambassadors' ? sortDirection : undefined"
          @sorting-button-clicked="setSorting('pending_ambassadors')"
          compact
        >
          Pending Ambassadors
        </TableHeadColumn>
        <TableHeadColumn
          align="left"
          sortable
          :sorted="sortBy === 'unread_messages' ? sortDirection : undefined"
          @sorting-button-clicked="setSorting('unread_messages')"
          compact
        >
          Unread Messages
        </TableHeadColumn>
        <TableHeadColumn
          align="left"
          sortable
          :sorted="sortBy === 'last_quest' ? sortDirection : undefined"
          @sorting-button-clicked="setSorting('last_quest')"
          compact
        >
          Last Quest
        </TableHeadColumn>
      </TableHead>
      <TableBody>
        <template v-if="isLoading">
          <TableRow v-for="i in Array(5)" :key="i">
            <TableColumnSkeleton v-for="j in Array(6).keys()" :key="j" />
          </TableRow>
        </template>

        <TableRow v-else v-for="organisation in metrics" :key="organisation.id">
          <TableColumn>{{ organisation.name }}</TableColumn>
          <TableColumn>
            <StatusBadge v-if="organisation.openReviews > 0" type="info">
              {{ organisation.openReviews }}
            </StatusBadge>
            <span v-else>-</span>
          </TableColumn>
          <TableColumn>
            <StatusBadge v-if="organisation.unfulfilledRewards > 0" type="info">
              {{ organisation.unfulfilledRewards }}
            </StatusBadge>
            <span v-else>-</span>
          </TableColumn>
          <TableColumn>
            <StatusBadge v-if="organisation.pendingAmbassadors > 0" type="info">
              {{ organisation.pendingAmbassadors }}
            </StatusBadge>
            <span v-else>-</span>
          </TableColumn>
          <TableColumn>
            <StatusBadge v-if="organisation.unreadMessages > 0" type="info">
              {{ organisation.unreadMessages }}
            </StatusBadge>
            <span v-else>-</span>
          </TableColumn>
          <TableColumn :no-wrap="true">
            <span v-if="organisation.lastQuest">
              <SimpleDateDisplay
                :date="organisation.lastQuest"
                :locale="locale"
              />
            </span>
            <span v-else>-</span>
          </TableColumn>
        </TableRow>
      </TableBody>
    </Table>
  </AuthenticatedLayout>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import {
  Table,
  TableHead,
  TableHeadColumn,
  TableBody,
  TableRow,
  TableColumn,
  TableColumnSkeleton,
  DashboardTitle,
  StateTitle,
  StateSubTitle,
  EmptyDashboardState,
  ErrorDashboardState,
  StatusBadge,
  SimpleDateDisplay,
} from '@ambassy/innercrowd-ui';
import { BuildingOfficeIcon } from '@heroicons/vue/24/outline';
import { apiClient } from '@ambassy/api-client';
import { OrganisationMetricsResource } from './Resources/organisationMetricsResource';
import { useOrganisationMetricsStore } from './Stores/organisationMetricsStore';
import { storeToRefs } from 'pinia';
import AuthenticatedLayout from '../../../Layouts/AuthenticatedLayout.vue';
import { FetchOrganisationMetricsRequest } from '@ambassy/schema';

const organisationMetricsStore = useOrganisationMetricsStore();
const { metrics, isLoading, error } = storeToRefs(organisationMetricsStore);
const organisationMetricsResource = new OrganisationMetricsResource(apiClient);

const locale = navigator.language;

const sortBy = ref<FetchOrganisationMetricsRequest['sortBy'] | undefined>(
  'name',
);
const sortDirection = ref<
  FetchOrganisationMetricsRequest['sortDirection'] | undefined
>('asc');

const fetchMetrics = () => {
  organisationMetricsStore.startLoading();
  organisationMetricsStore.clearError();
  organisationMetricsResource
    .fetchMetrics(sortBy.value, sortDirection.value)
    .then(organisationMetricsStore.setMetrics)
    .catch(organisationMetricsStore.setError)
    .finally(organisationMetricsStore.stopLoading);
};

const setSorting = (column: FetchOrganisationMetricsRequest['sortBy']) => {
  if (sortBy.value === column) {
    if (sortDirection.value === 'desc') {
      sortDirection.value = 'asc';
    } else {
      sortBy.value = undefined;
      sortDirection.value = undefined;
    }
  } else {
    sortBy.value = column;
    sortDirection.value = 'desc';
  }
  fetchMetrics();
};

onMounted(fetchMetrics);
</script>

import { defineStore } from 'pinia';
import { apiClient, ClientError, User } from '@ambassy/api-client';

export const useAccountStore = defineStore('account', {
  state: () => ({
    isLoading: true,
    user: null as User | null,
    error: null as string | null,
  }),
  getters: {
    selectedUserOrganisation: (state) => {
      return state.user?.user?.organisations?.find(
        (org: { id: string }) => org.id === state.user?.organisationId,
      );
    },
  },
  actions: {
    setUser(user: User) {
      this.user = user;
    },
    startLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
    setError(error: string) {
      this.error = error;
    },
    clearError() {
      this.error = null;
    },
  },
});

<template>
  <td
    :class="[
      align === 'left' && 'text-left',
      align === 'center' && 'text-center',
      align === 'right' && 'text-right',
      'px-3 py-4 text-sm text-gray-500 relative',
      noWrap && 'whitespace-nowrap',
    ]"
  >
    <div
      v-if="isSelectedRow && isFirstChild"
      class="absolute inset-y-0 left-0 w-0.5 bg-indigo-600"
    ></div>
    <slot />
  </td>
</template>

<script setup lang="ts">
import { SelectedRowContextId } from './context';
import { inject } from 'vue';

withDefaults(
  defineProps<{
    align?: 'left' | 'center' | 'right';
    isFirstChild?: boolean;
    noWrap?: boolean;
  }>(),
  {
    align: 'left',
    isFirstChild: false,
    noWrap: false,
  },
);

const isSelectedRow = inject(SelectedRowContextId, false);
</script>

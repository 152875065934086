<template>
  <div class="flex flex-col max-w-xs text-center mx-auto mt-16">
    <ExclamationCircleIcon class="w-6 h-6 mx-auto text-red-600" />
    <StateTitle class="font-bold mt-4">An error occurred</StateTitle>
    <slot />
    <StateSubTitle class="text-gray-700">Please try again later</StateSubTitle>
    <PrimaryButton class="mt-8" @click="reload">
      <span>Reload</span>
    </PrimaryButton>
  </div>
</template>

<script setup lang="ts">
import { ExclamationCircleIcon } from '@heroicons/vue/20/solid';
import StateTitle from '../DashboardState/StateTitle.vue';
import StateSubTitle from '../DashboardState/StateSubTitle.vue';
import PrimaryButton from '../../atoms/Buttons/PrimaryButton.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const reload = () => {
  router.go(0);
};
</script>

import { ApiClientInterface } from '@ambassy/api-client';
import { OrganisationMetricsResponse } from '@ambassy/schema';

export class OrganisationMetricsResource {
  constructor(private readonly apiClient: ApiClientInterface) {}

  async fetchMetrics(
    sortBy?: string,
    sortDirection?: string,
  ): Promise<OrganisationMetricsResponse> {
    return this.apiClient.getWithError<OrganisationMetricsResponse>(
      `/api/v1/admin/organisations/metrics`,
      {
        sortBy,
        sortDirection,
      },
    );
  }
}

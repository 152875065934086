import { defineStore } from 'pinia';
import ReviewSubmissionsResource from '../Resources/reviewSubmissionsResource';
import { apiClient } from '@ambassy/api-client';
import { ReviewStatus } from '../../../Kernel/Core/Review/reviewStatus';
import { ReviewSubmissionsCountResponse } from '../Resources/types';

export const useReviewCountStore = defineStore('reviewCount', {
  state: () => ({
    isLoading: true,
    reviewCount: 0,
    error: null as string | null,
  }),
  actions: {
    async startLoading() {
      this.isLoading = true;
    },
    async clearError() {
      this.error = null;
    },
    async stopLoading() {
      this.isLoading = false;
    },
    async setError(error: string) {
      this.error = error;
    },
    async setReviewCount(count: ReviewSubmissionsCountResponse) {
      this.reviewCount = count.count;
    },
    async decrementReviewCount() {
      this.reviewCount -= 1;
    },
  },
});

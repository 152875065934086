<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="relative z-40 md:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel
              class="relative flex w-full max-w-xs flex-1 flex-col bg-indigo-700 pt-5 pb-4"
            >
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="flex flex-shrink-0 items-center px-4">
                <img
                  class="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=300"
                  alt="Your Company"
                />
              </div>
              <div class="mt-5 h-0 flex-1">
                <AppMenu />
              </div>
            </DialogPanel>
          </TransitionChild>
          <div class="w-14 flex-shrink-0" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div
      class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col bg-white border-r-2 z-30"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-grow flex-col pt-5 overflow-y-scroll">
        <div class="flex flex-shrink-0 items-center px-4">
          <img class="h-8 w-auto" :src="innercrowd" alt="Innercrowd" />
        </div>
        <div class="mt-5 flex flex-1 flex-col">
          <AppMenu />
        </div>
      </div>
    </div>
    <div class="flex flex-1 flex-col md:pl-64">
      <div
        class="sticky top-0 z-20 flex h-16 flex-shrink-0 bg-white border-b-2"
      >
        <button
          type="button"
          class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon class="h-6 w-6" aria-hidden="true" />
        </button>
        <NavBar />
      </div>

      <main>
        <div class="pt-7 pb-10 px-14">
          <slot />
        </div>
      </main>
    </div>
    <!--        <ModalComponent :modal="$page.props.modal"/>-->
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import { Bars3BottomLeftIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import NavBar from '../Components/NavBar.vue';
import AppMenu from '../Components/AppMenu.vue';
import innercrowd from '../logo/innercrowd.svg';
import initLoad from '../Support/User/initLoad';
import { apiClient } from '@ambassy/api-client';
import { UserOutput } from '@ambassy/schema';
import { useAccountStore } from '../Support/User/accountStore';
import { initIntercom } from '../../intercom';

const sidebarOpen = ref(false);

const accountStore = useAccountStore();

accountStore.$subscribe((mutation, state) => {
  if (state.user) {
    initIntercom(state.user);
  }
});

const fetchMe = () => {
  accountStore.startLoading();
  accountStore.clearError();
  apiClient
    .getWithError<UserOutput>('/api/v1/dashboard/me')
    .then(accountStore.setUser)
    .catch(accountStore.setError)
    .finally(accountStore.stopLoading);
};
fetchMe();

onMounted(() => initLoad());
</script>

<template>
  {{ display }}
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { displayDate } from './dateFunctions';

const props = withDefaults(
  defineProps<{
    date?: string | null | Date;
    locale?: string;
  }>(),
  {
    locale: 'en-US',
  },
);

const display = computed(() => displayDate(props.date, props.locale));
</script>

import './styles.css';

import router from './router';

import { createApp } from 'vue';
import App from './app/App.vue';
import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia';
import i18n from '@ambassy/translations';
import {
  browserTracingIntegration,
  feedbackIntegration,
  replayIntegration,
} from '@sentry/vue';
import { checkConfirmation } from './app/Support/Confirmation/confirmation';
import { pushNotification } from '@ambassy/api-client';

router.beforeEach(checkConfirmation);

const pinia = createPinia();
i18n.setupI18n();

const app = createApp(App)
  .use(router)
  .use(pinia)
  .use(i18n.vueI18n)
  .use(pushNotification, {
    broadcaster: import.meta.env.VITE_BROADCAST_DRIVER,
    authEndpoint: import.meta.env.VITE_REVERB_AUTH_ENDPOINT,
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT,
    wssPort: import.meta.env.VITE_REVERB_PORT,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
  });

if (import.meta.env.VITE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      browserTracingIntegration({ router }),
      replayIntegration(),
      feedbackIntegration({
        colorScheme: 'light',
      }),
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.mount('#root');

import { computed } from 'vue';

export const displayDate = (
  date?: string | null | Date,
  locale: string = 'en-US',
) => {
  // return - if date is undefined or null
  if (!date) return '-';

  date = date instanceof Date ? date : new Date(date);

  // return - if date is not a valid date
  if (isNaN(date.getTime())) return '-';

  // else return the dat in local format
  return date.toLocaleDateString(locale ?? 'en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
};

export const displayDateTime = (
  date: string | null | Date,
  locale: string = 'en-US',
) => {
  // return - if date is undefined or null
  if (!date) return '-';

  date = date instanceof Date ? date : new Date(date);

  // return - if date is not a valid date
  if (isNaN(date.getTime())) return '-';

  // else return the dat in local format
  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
};

export const displayTime = (
  date: string | null | Date,
  locale: string = 'en-US',
) => {
  // return - if date is undefined or null
  if (!date) return '-';

  date = date instanceof Date ? date : new Date(date);

  // return - if date is not a valid date
  if (isNaN(date.getTime())) return '-';

  // else return the dat in local format
  return date.toLocaleTimeString(locale, {
    hour: 'numeric',
    minute: 'numeric',
  });
};

export const humanizeDate = (
  date: string | null | Date | undefined,
  locale: string = 'en-US',
) => {
  if (!date) return '-';
  date = date instanceof Date ? date : new Date(date);
  if (isNaN(date.getTime())) return '-';

  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const humanizeDateTime = (
  date: string | null | Date | undefined,
  locale: string = 'en-US',
) => {
  if (!date) return '-';
  date = date instanceof Date ? date : new Date(date);
  if (isNaN(date.getTime())) return '-';

  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
};

export const displayDateFilterLabel = (
  locale: string,
  from: string | null,
  to: string | null,
) => {
  if (from && to) {
    return `${displayDateTime(from, locale)} - ${displayDateTime(to, locale)}`;
  }
  if (from) {
    return `From ${displayDateTime(from, locale)}`;
  }
  if (to) {
    return `To ${displayDateTime(to, locale)}`;
  }
  return null;
};

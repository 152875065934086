import { AmbassadorDashboardStatus } from '../types';

import type { ApiClientInterface } from '@ambassy/api-client';
import type { UserContact } from '../../../Kernel/Core/User/userContact';
import type { UserInformation } from '../../../Kernel/Core/User/userInformation';
import type { UserSocials } from '../../../Kernel/Core/User/userSocials';
import { AmbassadorCountResponse } from './types';
import {
  AmbassadorDetailResponse,
  AmbassadorsStatisticsResponse,
  PaginatedAmbassadorsWithDetailsOutput,
  AmbassadorStatisticsOutput,
  AmbassadorReviewSubmissionsResponse,
  AmbassadorDetailRewardsResponse,
  AmbassadorDetailSalesResponse,
  AmbassadorNotesResponse,
  AmbassadorNoteResponse,
  AmbassadorsWithStatusRequest,
  AmbassadorInfoOutput,
  AmbassadorDiscountCodesResponse,
  AmbassadorNotificationSettingsResponse,
} from '@ambassy/schema';

export interface AmbassadorWithDetails {
  name: string;
  phone: string;
  email: string;
  contact: UserContact;
  personalInformation: UserInformation[];
  information: { label: string; value: string }[];
  socials: { [key: string]: UserSocials };
  optIns: { type: string; isActive: boolean }[];
}

interface AmbassadorsResourceInterface {
  getAmbassadors(
    status: AmbassadorDashboardStatus,
    page: number,
    search?: string,
    tags?: { id: string }[],
    orderBy?: AmbassadorsWithStatusRequest['orderBy'],
    orderDirection?: AmbassadorsWithStatusRequest['orderDirection'],
    registeredFrom?: string,
    registeredTo?: string,
  ): Promise<PaginatedAmbassadorsWithDetailsOutput>;

  getAmbassadorsStatistics(
    ambassadorIds: string[],
  ): Promise<AmbassadorsStatisticsResponse>;

  approve(id: string): Promise<void>;

  reject(id: string): Promise<void>;

  disable(id: string): Promise<void>;

  getAmbassadorInfo(id: string): Promise<AmbassadorInfoOutput>;

  getAmbassador(id: string): Promise<AmbassadorDetailResponse>;

  getAmbassadorStatistics(id: string): Promise<AmbassadorStatisticsOutput>;

  addPoints(id: string, points: number, reason: string): Promise<void>;

  fetchAmbassadorSubmissions(
    ambassadorId: string,
    page: number,
  ): Promise<AmbassadorReviewSubmissionsResponse>;

  fetchAmbassadorClaims(
    ambassadorId: string,
    page: number,
  ): Promise<AmbassadorDetailRewardsResponse>;

  fetchAmbassadorSales(
    ambassadorId: string,
    page: number,
  ): Promise<AmbassadorDetailSalesResponse>;

  saveAmbassadorNote(
    ambassadorId: string,
    note: string,
  ): Promise<AmbassadorNoteResponse>;
  updateAmbassadorNote(
    ambassadorId: string,
    noteId: string,
    note: string,
  ): Promise<void>;

  fetchAmbassadorDiscounts(
    ambassadorId: string,
    page: number,
    perPage: number,
  ): Promise<AmbassadorDiscountCodesResponse>;

  fetchAmbassadorNotificationSettings(
    ambassadorId: string,
  ): Promise<AmbassadorNotificationSettingsResponse>;
}

export default class AmbassadorsResource
  implements AmbassadorsResourceInterface
{
  constructor(private readonly apiClient: ApiClientInterface) {}

  getAmbassadors(
    status: AmbassadorDashboardStatus,
    page: number,
    search?: string,
    tags?: { id: string }[],
    orderBy?: AmbassadorsWithStatusRequest['orderBy'],
    orderDirection?: AmbassadorsWithStatusRequest['orderDirection'],
    registeredFrom?: string,
    registeredTo?: string,
  ): Promise<PaginatedAmbassadorsWithDetailsOutput> {
    return this.apiClient.getWithError<PaginatedAmbassadorsWithDetailsOutput>(
      '/api/v1/ambassadors',
      {
        status,
        page,
        search,
        tags,
        orderBy,
        orderDirection,
        registeredFrom,
        registeredTo,
      },
    );
  }

  getAmbassadorsStatistics(
    ambassadorIds: string[],
  ): Promise<AmbassadorsStatisticsResponse> {
    if (ambassadorIds.length === 0) return Promise.resolve([]);
    return this.apiClient.getWithError('/api/v1/ambassadors/statistics', {
      ambassadorIds,
    });
  }

  approve(id: string) {
    return this.apiClient.post<void>(`/api/v1/ambassadors/${id}/approve`);
  }

  reject(id: string) {
    return this.apiClient.post<void>(`/api/v1/ambassadors/${id}/reject`);
  }

  disable(id: string) {
    return this.apiClient.postWithError<void>(
      `/api/v1/ambassadors/${id}/disable`,
    );
  }

  enable(id: string) {
    return this.apiClient.postWithError<void>(
      `/api/v1/ambassadors/${id}/enable`,
    );
  }

  getAmbassadorInfo(id: string): Promise<AmbassadorInfoOutput> {
    return this.apiClient.getWithError<AmbassadorInfoOutput>(
      `/api/v1/ambassadors/${id}/info`,
    );
  }

  getAmbassador(id: string): Promise<AmbassadorDetailResponse> {
    return this.apiClient.getWithError<AmbassadorDetailResponse>(
      `/api/v1/ambassadors/${id}`,
    );
  }

  async getAmbassadorCount(status: AmbassadorsWithStatusRequest['status']) {
    return this.apiClient.getWithError<AmbassadorCountResponse>(
      `/api/v1/ambassadors/count?status=${status}`,
    );
  }

  getAmbassadorStatistics(id: string): Promise<AmbassadorStatisticsOutput> {
    return this.apiClient.get(`/api/v1/ambassadors/${id}/statistics`);
  }

  sendLogin(id: string) {
    return this.apiClient.postWithError<void>(
      `/api/v1/ambassadors/${id}/send-login`,
    );
  }

  updateField(ambassadorId: string, id: string, value: string, slug: string) {
    return this.apiClient.postWithError<void>(
      `/api/v1/ambassadors/${ambassadorId}/update-field`,
      {
        fieldId: id,
        value,
        slug,
      },
    );
  }

  addPoints(id: string, points: number, reason: string) {
    return this.apiClient.postWithError<void>(
      `/api/v1/ambassadors/${id}/add-points`,
      {
        points,
        reason,
      },
    );
  }

  fetchAmbassadorSubmissions(
    ambassadorId: string,
    page: number,
  ): Promise<AmbassadorReviewSubmissionsResponse> {
    return this.apiClient.getWithError(
      `/api/v1/ambassadors/${ambassadorId}/submissions`,
      {
        page,
      },
    );
  }

  fetchAmbassadorClaims(
    ambassadorId: string,
    page: number,
  ): Promise<AmbassadorDetailRewardsResponse> {
    return this.apiClient.getWithError(
      `/api/v1/ambassadors/${ambassadorId}/rewards`,
      {
        page,
      },
    );
  }

  fetchAmbassadorSales(
    ambassadorId: string,
    page: number,
  ): Promise<AmbassadorDetailSalesResponse> {
    return this.apiClient.getWithError(
      `/api/v1/ambassadors/${ambassadorId}/sales`,
      {
        page,
      },
    );
  }

  fetchAmbassadorNotes(ambassadorId: string) {
    return this.apiClient.getWithError<AmbassadorNotesResponse>(
      `/api/v1/ambassadors/${ambassadorId}/notes`,
    );
  }

  saveAmbassadorNote(
    ambassadorId: string,
    note: string,
  ): Promise<AmbassadorNoteResponse> {
    return this.apiClient.postWithError<AmbassadorNoteResponse>(
      `/api/v1/ambassadors/${ambassadorId}/notes`,
      {
        note,
      },
    );
  }

  updateAmbassadorNote(
    ambassadorId: string,
    noteId: string,
    note: string,
  ): Promise<void> {
    return this.apiClient.patchWithError<void>(
      `/api/v1/ambassadors/${ambassadorId}/notes/${noteId}`,
      {
        note,
      },
    );
  }

  fetchAmbassadorDiscounts(
    ambassadorId: string,
    page: number,
    perPage: number,
  ): Promise<AmbassadorDiscountCodesResponse> {
    return this.apiClient.getWithError<AmbassadorDiscountCodesResponse>(
      `/api/v1/ambassadors/${ambassadorId}/discount-codes`,
      { page, perPage },
    );
  }

  fetchAmbassadorNotificationSettings(
    ambassadorId: string,
  ): Promise<AmbassadorNotificationSettingsResponse> {
    return this.apiClient.getWithError<AmbassadorNotificationSettingsResponse>(
      `/api/v1/ambassadors/${ambassadorId}/notification-settings`,
    );
  }
}

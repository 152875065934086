import { App, inject } from 'vue';

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

// @ts-ignore
window.Pusher = Pusher;

let _app: App | null = null;
let _options: any;
const pushNotification = {
  install: (app: App, options: any) => {
    _app = app;
    _options = options;
  },
};

const authorisePushNotifications = async (token: string) => {
  if (!_app) {
    throw new Error('Push notification plugin not installed');
  }
  console.log('authorisePushNotifications', token);
  try {
    const echo = new Echo({
      ..._options,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });
    console.log('echo', echo);
    _app.provide('pushNotification', echo);
  } catch (error) {
    console.log('error', error);
  }
};

// @ts-ignore-next-line
const usePushNotification = (): Echo<'pusher'> => inject('pushNotification');

export { pushNotification, usePushNotification, authorisePushNotifications };

<template>
  <span
    :class="[
      color,
      'inline-flex items-center rounded px-2.5 py-1 text-sm font-medium',
    ]"
  >
    <slot />
  </span>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  type: 'info' | 'success' | 'warning' | 'danger' | 'default';
}>();

const color = computed(() => {
  switch (props.type) {
    case 'info':
      return 'bg-blue-100 text-blue-800';
    case 'success':
      return 'bg-green-100 text-green-800';
    case 'warning':
      return 'bg-yellow-100 text-yellow-800';
    case 'danger':
      return 'bg-red-100 text-red-800';
    case 'default':
    default:
      return 'bg-gray-100 text-gray-800';
  }
});
</script>

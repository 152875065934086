import {
  storageService,
  StorageServiceInterface,
  tabStorageService,
} from '../storage/storageService';
import {
  API_TOKEN_STORAGE_EXPIRES_AT,
  API_TOKEN_STORAGE_KEY,
  USER_SELECTED_ORGANISATION_ID,
} from '../storage/storageKeys';
import { apiClient } from '../client/apiClient';
import { ExchangeTokenResponse, PermissionsOutput } from '@ambassy/schema';

export interface AuthenticationServiceInterface {
  isLoggedIn(): Promise<boolean>;

  redirectToLogin(redirectUrl: string): void;

  getToken(): Promise<string>;

  checkDashboardPermissions(): Promise<PermissionsOutput>;

  checkAppPermissions(): Promise<PermissionsOutput>;

  getSelectedOrganisationId(): Promise<string | null>;

  isLegacyToken(): Promise<boolean>;

  exchangeLegacyToken(): any;
}

export const useAuthenticationServiceAdapter = (
  storage: StorageServiceInterface,
  tabStorage: StorageServiceInterface,
): AuthenticationServiceInterface => {
  return {
    checkAppPermissions(): Promise<PermissionsOutput> {
      return apiClient.getWithError<PermissionsOutput>(
        '/api/v1/app/permissions',
      );
    },
    async isLegacyToken(): Promise<boolean> {
      const token = await storage.getItem(API_TOKEN_STORAGE_KEY);
      if (!token) {
        return false;
      }
      return token.includes('|');
    },
    async exchangeLegacyToken(): Promise<void> {
      const token = await storage.getItem(API_TOKEN_STORAGE_KEY);
      const response = await apiClient.postWithError<ExchangeTokenResponse>(
        '/api/v1/exchange-token',
        { token },
      );

      storageService.setItem(API_TOKEN_STORAGE_KEY, response.token);
      storageService.setItem(API_TOKEN_STORAGE_EXPIRES_AT, response.expiresAt);
    },
    async isLoggedIn(): Promise<boolean> {
      return !!(await storage.getItem(API_TOKEN_STORAGE_KEY));
    },
    redirectToLogin(redirectUrl: string): void {
      window.location.href = `/login?redirect=${redirectUrl}`;
    },
    checkDashboardPermissions(): Promise<PermissionsOutput> {
      return apiClient.getWithError<PermissionsOutput>(
        '/api/v1/dashboard/permissions',
      );
    },
    getSelectedOrganisationId(): Promise<string | null> {
      return tabStorageService.getItem(USER_SELECTED_ORGANISATION_ID);
    },
    async getToken(): Promise<string> {
      return new Promise(async (resolve, reject) => {
        const token = await storage.getItem(API_TOKEN_STORAGE_KEY);

        if (!token) {
          return reject('Token is not available');
        }

        return resolve(token);
      });
    },
  };
};

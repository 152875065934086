import { defineStore } from 'pinia';
import {
  ApiError,
  ClientErrorInterface,
  SubmitAmbassadorOptInRequest,
} from '@ambassy/schema';
import { useTheme } from '../../../theme/themeContext';
import {
  CountryCode,
  isValidPhoneNumber,
  parsePhoneNumber,
  getCountryCallingCode,
} from 'libphonenumber-js';

const theme = useTheme();
export const useRegisterForOrganisationStore = defineStore(
  'registerForOrganisation',
  {
    state: () => ({
      fields: {} as SubmitAmbassadorOptInRequest,
      isSubmitting: false,
      errors: {} as ApiError<SubmitAmbassadorOptInRequest>,
      errorMessage: null as string | null,
      phoneNumberCountryCode: theme.value.phoneCode as string,
    }),
    actions: {
      setField<K extends keyof SubmitAmbassadorOptInRequest>(
        field: K,
        value: SubmitAmbassadorOptInRequest[K] | null,
      ) {
        this.fields[field] = value;
      },
      startSubmitting() {
        this.isSubmitting = true;
      },
      clearErrors() {
        this.errors = {};
        this.errorMessage = null;
      },
      stopSubmitting() {
        this.isSubmitting = false;
      },
      setErrors(errors: ClientErrorInterface<SubmitAmbassadorOptInRequest>) {
        this.errors = errors.getErrors();
        this.errorMessage = errors.message;
      },

      setLandingPageFromQueryParams(queryParams: Record<string, string>) {
        for (const [slug, value] of Object.entries(queryParams)) {
          if (slug === 'phone') {
            this.fields[slug] = this._getFullPhoneNumberWithCallingCode(value);
          } else {
            this.fields[slug] =
              value as SubmitAmbassadorOptInRequest[keyof SubmitAmbassadorOptInRequest];
          }
        }
      },
      /**
       * Validates and formats a phone number, ensuring it has the correct country calling code
       * @param value Raw phone number input
       * @returns Formatted international phone number
       * @private
       */
      _getFullPhoneNumberWithCallingCode(value: string): string {
        const sanitizedPhoneNumber = `${value.replace(/[^\d+]/g, '')}`;
        const phone = sanitizedPhoneNumber.startsWith('+')
          ? sanitizedPhoneNumber
          : `+${sanitizedPhoneNumber}`;
        if (isValidPhoneNumber(phone)) {
          const parsedNumber = parsePhoneNumber(phone);
          this.phoneNumberCountryCode = parsedNumber.country || '';
          return parsedNumber.formatInternational();
        }

        this.phoneNumberCountryCode = theme.value.phoneCode;
        return `+${getCountryCallingCode(theme.value.phoneCode as CountryCode)}${value}`;
      },
    },
    getters: {
      getField: (state) => (fieldSlug: string) => {
        return state.fields[fieldSlug];
      },
      getError: (state) => (fieldSlug: string) => {
        return state.errors[fieldSlug];
      },
    },
  },
);

import { storageService, tabStorageService } from '../storage';
import {
  API_TOKEN_STORAGE_EXPIRES_AT,
  API_TOKEN_STORAGE_KEY,
  USER_SELECTED_ORGANISATION_ID,
  USER_STORAGE_KEY,
} from '../storage/storageKeys';
import { UserOutput } from '@ambassy/schema';

export const setUser = (user: UserOutput) => {
  storageService.setItem(USER_STORAGE_KEY, JSON.stringify(user));
};

export const setOrganisation = (organisationId: string) => {
  useUser().then((user) => {
    user.organisationId = organisationId;
    tabStorageService.setItem(USER_SELECTED_ORGANISATION_ID, organisationId);
    setUser(user);
  });
};

export const useUser = async (): Promise<UserOutput> => {
  const organisationId = await useOrganisationId();
  return storageService.getItem(USER_STORAGE_KEY).then((storedUser) => {
    const user = JSON.parse(storedUser || '{}');
    if (organisationId) {
      user.organisationId = organisationId;
    }
    return user;
  });
};

export const useOrganisationId = async (): Promise<string> => {
  return tabStorageService
    .getItem(USER_SELECTED_ORGANISATION_ID)
    .then((organisationId) => {
      return organisationId || '';
    });
};

export const logout = () => {
  storageService.removeItem(API_TOKEN_STORAGE_KEY);
  storageService.removeItem(API_TOKEN_STORAGE_EXPIRES_AT);
  storageService.removeItem(USER_STORAGE_KEY);
  tabStorageService.removeItem(USER_SELECTED_ORGANISATION_ID);
};
